import '../../styles/pages/spring-summer-2022/spring-summer-2022-index.scss'
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet";
import Link from "gatsby-plugin-transition-link/AniLink";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import {springSummer2022Stories} from '../../components/spring-summer-2022-stories/stories';
import { Parallax, Background } from 'react-parallax';
import ScrollAnimation from 'react-animate-on-scroll';

import ArrowRightCircle from '../../images/icons/arrow-right-circle.svg'
import ArrowDownCircle from '../../images/icons/arrow-down-circle.svg'

import ITAT from '../../images/spring-2020/campaign-logo-horiz-with-tag-white.svg'
import CSUFNews from '../../images/fall-winter-2020/csuf-news.svg'

import HeroImage from '../../images/spring-summer-2022/cover.jpg'

import socialBanner from "../../images/fall-winter-2021/social-banners/main.jpg";


export default class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var pageTitle = 'Titan - The Digital Magazine of Cal State Fullerton';
    var pageDesc = 'TITAN, the signature magazine of Cal State Fullerton.. Here you will find bold and inspiring stories about the university’s people and programs — stories that will keep you connected to your CSUF family, demonstrate the impact of your support and make you feel proud to be a Titan.';

    // var heroStyles = {
    //   backgroundColor: '#222',
    //   backgroundImage: `url(${cover})`,
    //   backgroundRepeat: 'no-repeat',
    //   backgroundPosition: 'center center',
    //   backgroundSize: 'cover',
    //   color: 'white',
    //   height: '1200px'
    // }

    const list = springSummer2022Stories.map(function(story){
      if(story.slug != 'transforming-the-future' && story.slug != 'presidents-message') {
        var url = "/spring-summer-2022/" + story.slug + "/";
        var classname = "story-link feature " + story.slug;

        var linkStyle = {
          backgroundImage: `url(${story.thumbnailIndex})`
        }

        var title = <h3>{story.title}</h3>

        var subtitle = null;
        if (story.subtitle && story.subtitle != '') {
          subtitle = <span className="subtitle">{story.subtitle}</span>
        }
        
        // if (story.storyType == 'feature') {
          var storyText = <div className="story-text">
            <ScrollAnimation animateOnce={true} animateIn="fadeInUp">{title}</ScrollAnimation>
            <ScrollAnimation animateOnce={true} animateIn="fadeInDown">{subtitle}</ScrollAnimation>
          </div>
        // } else {
        //   var storyText = <div className="story-text">{title}</div>
        // }


        return (
            <Link
            style={linkStyle}
            className={classname}
            paintDrip
            hex="#FF7900"
            duration={.75}
            to={url}>
              {storyText}
            </Link>
        )
      }
    })

    return (
      <Layout locationInfo={this.props.location} issue="spring-summer-2022">
        <Helmet>
          <body className="spring-summer-2022-index" />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        <h1 style={{display: 'none'}}>Titan: Fall/Winter 2021</h1>

        <Parallax
          bgImage={HeroImage}
          bgImageAlt=""
          strength={100}
          className="cover animated fadeIn slower">

            <div className="hero-text">
              <h2 className="animated fadeInDown">Transforming the Future</h2>
              <p className="subtitle animated fadeInDown delay-1s">First-Generation Students Set the Path for Success</p>
              <Link
              className="button2 animated fadeInDown delay-2s"
              paintDrip
              hex="#000000"
              duration={.75}
              to="/spring-summer-2022/transforming-the-future/">
                Read Story <img src={ArrowRightCircle} alt="" className="icon" />
              </Link>
              <AnchorLink href='#intro' className="button2 more-stories-button animated fadeInDown delay-2s">
                More Stories <img src={ArrowDownCircle} alt="" className="icon" />
              </AnchorLink>
            </div>
            {/* <div className="tint" /> */}
            <div className="height" />
          </Parallax>

          <section id="intro">
            <h2 className="center"><em>In This Issue:</em></h2>
            <div className="wrap story-list">
              {list}
            </div>
          </section>
        
          <section className="external">
            <a href="https://campaign.fullerton.edu/" target="_blank" className="itat external-block">
              <h3><img src={ITAT} alt="It Takes a Titan - The Campaign for Cal State Fullerton" style={{width: '400px'}} /></h3>
              {/* <h3>It Takes a Titan</h3> */}
              {/* <p className="sub-head">The Campaign for Cal State Fullerton</p> */}
              <p>The university’s first-ever comprehensive philanthropic campaign aims to elevate the potential of Titan students. The $250 million initiative prioritizes investing in projects that enhance academic innovation, empower students, transform campus structures and enrich the community.</p>
              <img src={ArrowRightCircle} alt="" className="arrow-right-circle" />
            </a>
            <div className="csuf-news external-block">
              <h3><img src={CSUFNews} alt="CSUF News" style={{width: '250px'}}  /></h3>
              {/* <ul>
                <li><a href="https://news.fullerton.edu/2021/10/university-earns-seal-of-excelencia-for-advancing-latinx-student-success/?utm_source=titan-mag-spring-summer-2022&utm_medium=website&utm_campaign=story-promotion" target="_blank" rel="noreferrer">University Earns Seal of Excelencia for Advancing Latinx Student Success</a></li>
                <li><a href="https://news.fullerton.edu/2022/02/augie-garrido-baseball-statue/?utm_source=titan-mag-spring-summer-2022&utm_medium=website&utm_campaign=story-promotion" target="_blank" rel="noreferrer">Titan Athletics Unveils Augie Garrido Statue</a></li>
                <li><a href="https://news.fullerton.edu/2022/03/titansgive-donors-show-overwhelming-support-for-titans-in-fun-filled-day-of-giving/?utm_source=titan-mag-spring-summer-2022&utm_medium=website&utm_campaign=story-promotion" target="_blank" rel="noreferrer">#TitansGive Donors Show Overwhelming Support for Titans in Fun-Filled Day of Giving</a></li>
                <li><a href="https://news.fullerton.edu/2021/09/csuf-jumps-to-top-5-in-social-mobility-and-value/?utm_source=titan-mag-spring-summer-2022&utm_medium=website&utm_campaign=story-promotion" target="_blank" rel="noreferrer">CSUF Jumps to Top 5 in the Nation for Social Mobility and Value</a></li>
              </ul> */}
              <p>Stay up to date on all the latest campus news by visiting the <a href="http://news.fullerton.edu/" target="_blank" rel="noreferrer">CSUF News website</a>. Submit story ideas to <a href="mailto:newssubmissions@fullerton.edu">newssubmissions@fullerton.edu</a>.</p>
              {/* <img src={ArrowRightCircle} alt="" className="arrow-right-circle" /> */}
            </div>
          </section>
      </Layout>
    );
  }
}